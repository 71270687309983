export const coreCategoryDetailUrl =
  "/wow/seller/registration/getCoreCategoryDetail";
export const coreCategoryDetailUrlAppUrl =
  "app-seller-ondc-getCoreCategoryDetail";

export const nullVarientProductList =
  "/wow/seller/inventory/getProductListForVariantConfiguration";
export const nullVarientProductListAppUrl =
  "app-seller-ondc-getProductListForVariantConfiguration";

export const addProductVariantUrl = "/wow/seller/inventory/addProductVariant";
export const addProductVariantUrlAppUrl = "app-seller-ondc-addProductVariant";

export const productCategory =
  "/wow/seller/inventory/getSellerProductCategories";
export const productCategoryAppUrl =
  "app-seller-ondc-getSellerProductCategories";

export const updateProduct = "/wow/seller/inventory/updateProductInventory";
export const updateProductAppUrl = "app-seller-ondc-updateProductInventory";

export const productSubCategory = "/wow/seller/inventory/getProductSubCategory";
export const getProductSubCategoryAppUrl =
  "app-seller-ondc-getProductSubCategory";

export const productSubCategoryForFilter =
  "/wow/seller/inventory/wow/getProductSubCategory";
export const productSubCategoryForFilterAppUrl =
  "app-seller-ondc-getProductSubCategory";

export const setProductAvailabilityStatus =
  "wow/seller/inventory/setProductAvailabilityStatus";
export const setProductAvailabilityStatusAppUrl =
  "app-seller-ondc-setProductAvailabilityStatus";

export const updateProductStatus = "wow/seller/inventory/update-product-status";
export const updateProductStatusAppUrl = "app-seller-ondc-updateProductStatus";

export const getPanDetails = "wow/seller/registration/get-pan-details";
export const getPanDetailsAppUrl = "app-seller-ondc-getPanDetails";

export const getHsnDetailsUrl =
  "/wow/seller/inventory/wow/get-hsn-detail-by-desc";
export const getHsnDetailsUrlAppUrl = "app-seller-ondc-getHsnDetailByDesc";

export const getExportOrdersFileUrl = "wow/seller/inventory/getOrderByFilter";
export const getExportOrdersFileUrlAppUrl = "app-seller-ondc-getOrderByFilter";

// Add Product
export const addProductUrl = "/wow/seller/inventory/addProductDetail";
export const addProductUrlAppUrl = "app-seller-ondc-addProductDetail";

export const addProductImageUrl = "/wow/seller/inventory/addProductImage";
export const addProductImageUrlAppUrl = "app-seller-ondc-addProductImage";

export const sellerProductList = "/wow/seller/inventory/getSellerProductList";
export const sellerProductListAppUrl = "app-seller-ondc-getSellerProductList";

export const getSellerProductDetailsUrl =
  "/wow/seller/inventory/getSellerProductDetail";
export const getSellerProductDetailAppUrl =
  "app-seller-ondc-getSellerProductDetail";

export const updateProductDetailUrl =
  "/wow/seller/inventory/updateProductDetail";
export const updateProductDetailUrlAppUrl =
  "app-seller-ondc-updateProductDetail";

//My variant
export const myVariant = "app-seller-ondc-getVariantGroupList";
export const variantProductList = "app-seller-ondc-getProductListByVariant";

//Inventory Check
export const getInventoryDetail =
  "wow/seller/inventory/getSellerInventoryTrailDetails";
export const getInventoryDetailAppUrl =
  "app-seller-ondc-getSellerInventoryTrailDetails";

// Order
export const sellerOrderList = "wow/seller/inventory/getOrderByFilter";
export const sellerOrderListAppUrl = "app-seller-ondc-getOrderByFilter";

export const sellerOrderDetails = "wow/seller/inventory/getOrderByOrderId";
export const sellerOrderDetailsAppUrl = "app-seller-ondc-getOrderByOrderId";

export const getOrderStatusList =
  "/wow/seller/inventory/getOrderApplicableStatus";
export const getOrderStatusListAppUrl =
  "app-seller-ondc-getOrderApplicableStatus";

export const updateOrderStatusUrl =
  "/wow/seller/inventory/updateOrderCurrentStatus";
export const updateOrderStatusUrlAppUrl =
  "app-seller-ondc-updateOrderCurrentStatus";

export const getCancellationReasonList =
  "/wow/seller/inventory/getCancellationReasonList";
export const getCancellationReasonListAppUrl =
  "app-seller-ondc-getCancellationReasonList";

export const cancelOrderUrl = "/wow/seller/inventory/cancelOrderItemWise";
export const cancelOrderUrlAppUrl = "app-seller-ondc-cancelOrderItemWise";

//Offer
export const getOfferStatusList = "/wow/seller/inventory/getSellerOfferList";
export const getOfferStatusListAppUrl = "app-seller-ondc-getSellerOfferList";

export const getSellerOfferDetail =
  "/wow/seller/inventory/getSellerOfferDetail";
export const getSellerOfferDetailAppUrl =
  "app-seller-ondc-getSellerOfferDetail";

export const changeOfferStatus = "/wow/seller/inventory/changeOfferStatus";
export const changeOfferStatusAppUrl = "app-seller-ondc-changeOfferStatus";

export const configureSellerOffer =
  "/wow/seller/inventory/configureSellerOffer";
export const configureSellerOfferAppUrl =
  "app-seller-ondc-configureSellerOffer";

// Return Order
export const returnOrderListUrl =
  "/wow/seller/inventory/getOrderReturnByFilter";
export const returnOrderListUrlAppUrl =
  "app-seller-ondc-getOrderReturnByFilter";

export const returnOrderDetailsUrl =
  "/wow/seller/inventory/getReturnOrderDetailByReturnOrderTransactionId";
export const returnOrderDetailsUrlAppUrl =
  "app-seller-ondc-getReturnOrderDetailByReturnOrderTransactionId";

export const getReturnOrderStatusList =
  "/wow/seller/inventory/getReturnOrderApplicableStatus";
export const getReturnOrderStatusListAppUrl =
  "app-seller-ondc-getReturnOrderApplicableStatus";

// Issue and grievances
export const issueListUrl = "/wow/seller/igm/getIssueByFilter";
export const issueListUrlAppUrl = "app-seller-ondc-getIssueByFilter";

export const getIssueStatusList = "/wow/seller/igm/getIGMApplicableStatus";
export const getIssueStatusListAppUrl =
  "app-seller-ondc-getIGMApplicableStatus";

export const updateIssueStatusUrl = "/wow/seller/igm/changeIssueStatus";
export const updateIssueStatusUrlAppUrl = "app-seller-ondc-changeIssueStatus";

export const issueDetailsUrl = "/wow/seller/igm/getIssueDetail";
export const issueDetailsUrlAppUrl = "app-seller-ondc-getIssueDetail";

// Asking for FSSAI
export const checkFssaiUrl =
  "/wow/seller/registration/checkFassaiDocumentPresent";
export const checkFssaiUrlAppUrl = "app-seller-ondc-checkFassaiDocumentPresent";
// export const checkFssaiUrlAppUrl = "app-seller-ondc-checkFassaiDocumentPresent";

// Profile
export const profileInfoUrl = "/wow/seller/registration/getAllDetailOfSeller";
export const profileInfoUrlAppUrl = "app-seller-ondc-getAllDetailOfSeller";

export const editProfileDetailsUrl =
  "/wow/seller/registration/profile/editProfileDetail";
export const editProfileDetailsUrlAppUrl = "app-seller-ondc-editProfileDetail";

// Dashboard
export const dashboardDetailsUrl = "/wow/seller/inventory/getOrderDashboardDtl";
export const dashboardDetailsUrlAppUrl = "app-seller-ondc-getOrderDashboardDtl";

export const storeStatusChangeUrl = "/wow/seller/registration/setStoreStatus";
export const storeStatusChangeUrlAppUrl = "app-seller-ondc-setStoreStatus";

// Customer
export const customerListUrl = "/wow/seller/inventory/getCustomerList";
export const customerListUrlAppUrl = "app-seller-ondc-getCustomerList";

// Buyer
export const buyerListUrl = "/wow/seller/registration/getAllBuyerDetails";
export const buyerListUrlAppUrl = "app-seller-ondc-getAllBuyerDetails";

export const buyerMappingUrl =
  "/wow/seller/registration/saveSellerBuyerMappingDtl";
export const buyerMappingUrlAppUrl =
  "app-seller-ondc-saveSellerBuyerMappingDtl";

// LSP
export const lspListUrl =
  "/wow/seller/registration/getAllLogisticPartnerDetails";
export const lspListUrlAppUrl = "app-seller-ondc-getAllLogisticPartnerDetails";

export const lspMappingUrl =
  "/wow/seller/registration/saveSellerLogisticPartnerMappingDtl";
export const lspMappingUrlAppUrl =
  "app-seller-ondc-saveSellerLogisticPartnerMappingDtl";

//MultiUsers
export const getStoreUserList = "/wow/seller/registration/getStoreUserList";
export const getStoreUserListAppUrl = "app-seller-ondc-getStoreUserList";

export const addNewStoreUser = "/wow/seller/registration/addNewStoreUser";
export const addNewStoreUserAppUrl = "app-seller-ondc-addNewStoreUser";

export const getMultiUserPermissions =
  "/wow/seller/registration/getMappedUnmappedPermissions";
export const getMultiUserPermissionsAppUrl =
  "app-seller-ondc-getMappedUnmappedPermissions";

export const updateStoreUser = "/wow/seller/registration/updateStoreUser";
export const updateStoreUserAppUrl = "app-seller-ondc-updateStoreUser";

// Payments
export const paymentDetailsUrl = "/wow/seller/inventory/getSettlementDetails";
export const paymentDetailsUrlAppUrl = "app-seller-ondc-getSettlementDetails";

//Bulk Invoice List
export const bulkInovoiceGenerateUrl =
  "/wow/seller/inventory/generateBulkInvoice";
export const bulkInovoiceGenerateUrlAppUrl =
  "app-seller-ondc-generateBulkInvoice";
// export const bulkInovoiceGenerateUrlAppUrl = "app-seller-ondc-getSettlementDetails";

//Downloads
export const downloadsListUrl =
  "/wow/seller/inventory/getRepostDownloadListByFilter";
export const downloadsListUrlAppUrl =
  "app-seller-ondc-getRepostDownloadListByFilter";

//Save contact us querry
export const contactUsQuerry = "/wow/seller/registration/saveEnquiryDetail";
export const contactUsQuerryUrlAppUrl = "app-seller-ondc-saveEnquiryDetail";

const sendOtpUrl = "/wow/seller/registration/generateOtp";
export const sendOtpUrlAppUrl = "app-seller-ondc-generateOtp";

const verifyMobileOtpUrl = "/wow/seller/registration/validateOtp";
export const verifyMobileOtpUrlAppUrl = "app-seller-ondc-validateOtp";

const registrationUrl = "/wow/seller/registration/addSellerBasicProfile";
export const registrationUrlAppUrl = "app-seller-ondc-addSellerBasicProfile";

const loginUrl = "/wow/seller/registration/doSellerLogin";
export const loginUrlAppUrl = "app-seller-ondc-doSellerLogin";

const resetPasswordUrl = "/wow/seller/registration/resetPassword";
export const resetPasswordUrlAppUrl = "app-seller-ondc-resetPassword";

const uploadDocumentUrl = "/wow/seller/registration/uploadSellerDocument";
export const uploadDocumentUrlAppUrl = "app-seller-ondc-uploadSellerDocument";

const sellerAddressUrl = "/wow/seller/registration/addSellerAddress";
export const sellerAddressUrlAppUrl = "app-seller-ondc-addSellerAddress";

const pincodeUrl = "/wow/seller/registration/getPincodeDetail";
export const pincodeUrlAppUrl = "app-seller-ondc-getPincodeDetail";

const SellerDetailsUrl = "/wow/seller/registration/getSellerDetailByMobile";
export const SellerDetailsUrlAppUrl = "app-seller-ondc-getSellerDetailByMobile";

const getIfscDetailsUrl = "/wow/seller/registration/getIfscDetail";
export const getIfscDetailsUrlAppUrl = "app-seller-ondc-getIfscDetail";

const saveBankDetailsUrl = "/wow/seller/registration/addSellerBankDetail";
export const saveBankDetailsUrlAppUrl = "app-seller-ondc-addSellerBankDetail";

const storeTimingUrl = "/wow/seller/registration/addStoreTiming";
export const storeTimingUrlAppUrl = "app-seller-ondc-addStoreTiming";

const storeHolidayUrl = "/wow/seller/registration/addStoreHoliday";
export const storeHolidayUrlAppUrl = "app-seller-ondc-addStoreHoliday";

const storeSettingUrl = "/wow/seller/registration/addStoreSetting";
export const storeSettingUrlAppUrl = "app-seller-ondc-addStoreSetting";

// const updateProfileUrl = "/wow/seller/registration/updateSellerBasicProfile";
// export const updateProfileUrlAppUrl = "app-seller-ondc-updateSellerBasicProfile";

export const addBusinessProfileUrl =
  "/wow/seller/registration/addSellerBusinessProfile";
export const addBusinessProfileUrlAppUrl =
  "app-seller-ondc-addSellerBusinessProfile";

const addDeliveryChargesUrl = "/wow/seller/registration/setDeliveryCharge";
export const addDeliveryChargesUrlAppUrl = "app-seller-ondc-setDeliveryCharge";

//product
const getCategoryUrl = "/wow/seller/inventory/getProductCategory";
export const getCategoryUrlAppUrl = "app-seller-ondc-getProductCategory";

const getSubCategoryUrl = "/wow/seller/inventory/getProductSubCategory";
export const getSubCategoryUrlAppUrl = "app-seller-ondc-getProductSubCategory";

const getFieds = "/wow/seller/inventory/getProductAttributesBySubcategory";
export const getFiedsAppUrl =
  "app-seller-ondc-getProductAttributesBySubcategory";

//manageMultiStore
const getMultiStoresUrl = "/wow/seller/registration/getMultiStoreDetails";
export const getMultiStoresUrlAppUrl = "app-seller-ondc-getMultiStoreDetails";

const disableMappedStore = "/wow/seller/registration/disableMappedStore";
export const disableMappedStoreAppUrl = "app-seller-ondc-disableMappedStore";

const getPartnerProfile = "/wow/seller/registration/partner/getPartnerProfile";
// export const getPartnerProfileAppUrl = "app-seller-ondc-getPartnerProfile";
export const getPartnerProfileAppUrl = "app-seller-ondc-v2-getPartnerProfile";

//add serial number in order details
export const addSerialOrderNo =
  "/wow/seller/inventory/updateOrderProductDetailSerialNo";
export const addSerialOrderNoAppUrl =
  "app-seller-ondc-updateOrderProductDetailSerialNo";

export const addLspDetail = "app-seller-ondc-updateLogisticsDetails";

export const getFaqDetailsAppUrl = "app-seller-ondc-v2-getFaqDetails";
