import { encode } from 'base-64';
import CryptoJS from 'crypto-js';

export const getRandomNumber = () => {
  const randomStringLength = 100;
  let randomNumberString = '';

  while (randomNumberString.length < randomStringLength) {
    randomNumberString += Math.floor(Math.random() * 10);
  }

  return randomNumberString;
};

export const getKeyFromRandomNumber = (num) => {
  if (!num) return '';
  return num.slice(0, 4) + num.slice(num.length - 4, num.length);
};

export const sendData = (data) => {
  const num1 = getRandomNumber();
  const num2 = getRandomNumber();
  const key = getKeyFromRandomNumber(num1) + getKeyFromRandomNumber(num2);

  const encoded = encode(key);
  const dataString = JSON.stringify(data);
  const encodedKey = CryptoJS.enc.Base64.parse(encoded);

  const encrypted = CryptoJS.AES.encrypt(dataString, encodedKey, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });

  return `${num1}:${encrypted}:${num2}`;
};

export const getData = (encryptedData) => {
  const { key, content } = getKey(encryptedData);

  const decrypted = CryptoJS.AES.decrypt(
    content,
    CryptoJS.enc.Base64.parse(encode(key)),
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }
  );
  console.log('decrypted data',decrypted.toString(CryptoJS.enc.Utf8));
  return decrypted.toString(CryptoJS.enc.Utf8);
};

export const getKey = (data) => {
  const dataArr = data.split(':');
  if (!dataArr || dataArr.length !== 3) {
    throw new Error('Invalid encrypted data format.');
  }

  const firstKeyNumber = getKeyFromRandomNumber(dataArr[0]);
  const secondKeyNumber = getKeyFromRandomNumber(dataArr[2]);

  const key = firstKeyNumber + secondKeyNumber;
  const content = dataArr[1];
  return { key, content };
};
