import { useState } from "react";

import { sellerDetailsByMobile } from "../../Services/UserService";
import { SellerDetailsUrlAppUrl } from "../../Services/Constant";
import { HTTPS } from "../../../services/HTTPS";
import { generateRandomNumber } from "../../../util/utils";

function useKycDedupe() {
  const [loader, setLoader] = useState(false);
  const [dedupeRes, setDedupeRes] = useState();
  const userMobile = sessionStorage.getItem("mobile");

  const dedupCall = async () => {
    try {
      setLoader(true);

      const info = {
        HEADER: {
          mobile: userMobile,
          UDID: sessionStorage.getItem("ipAddress"),
          REQUEST_ID: generateRandomNumber(),
        },
        DATA: {},
      };

      const formResponse = await HTTPS(SellerDetailsUrlAppUrl, info);
      // if (response.status === 200) {
      //   const formResponse = response.data;

        if (formResponse.RESP_CODE === 300) {
          sessionStorage.setItem("sellerCode", formResponse.DATA.sellerCode);
          setDedupeRes(formResponse.DATA);
        } else {
          console.log(formResponse.RESP_MSG);
        }
      // } else {
      //   alert("Server issue, please try again later");
      // }

      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };
  return { dedupCall, loader, dedupeRes, setDedupeRes };
}

export default useKycDedupe;
