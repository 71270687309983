import axios from "axios";
let token = sessionStorage.getItem("vendor_token");
const secureApiUrls = [
  "vendor-service/allVendorList",
  "vendor-service/getVendorDetails",
  "vendor-service/getVendorWalletBalance",
  "/vendor-service/vendorDetailsExport",
  "/vendor-service/sellerReport",
];

const axiosDirect = axios.create({
  baseURL: process.env.REACT_APP_PORTAL_URL_DIRECT,
  //headers: headers
});

axiosDirect.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    if (secureApiUrls.includes(config.url) && token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default axiosDirect;
