import axios from "axios";
import { getData, sendData } from "../Utilities/Encryption";

// const HOST = 'https://ecspreprod.easypay.co.in:8080/web-common/on-common/';
const HOST = process.env.REACT_APP_PORTAL_URL;
const NO_ENCRYPTION_HOST = "";

export const JsonLogFormatter = (obj) => {
  return JSON.stringify(obj, null, 2);
};

export const HTTPS = async (URL, PAYLOAD, encryption = true) => {
  if (encryption) {
    try {
      console.log(`PAYLOAD(${URL}) => \n`, JsonLogFormatter(PAYLOAD));
      const formData = new FormData();
      formData.append("request", sendData(PAYLOAD));
      // console.log(`encrypted PAYLOAD(${URL})`, formData);
      const API = HOST + URL;
      console.log("Full URL ", API);

      const response = await axios.post(API, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // console.log(`encrypted response(${URL})`, response.data);
      // console.log(
      //   `RES ${getAPIName(URL)} => \n`,
      //   JsonLogFormatter(JSON.parse(getData(response.data))),
      // );
      return JSON.parse(getData(response.data));
    } catch (error) {
      // toast.error(error.message);
      // console.error('ERR', error);
      throw error;
    }
  } else {
    try {
      //  console.log(`PAYLOAD(${URL}) => \n`, JsonLogFormatter(PAYLOAD));
      const APIURL = NO_ENCRYPTION_HOST + URL;
      // console.log("APIURL",APIURL)
      const response = await axios.post(APIURL, PAYLOAD, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("RES", JsonLogFormatter(response.data));
      return response.data;
    } catch (error) {
      //  console.error('ERR', error.message);
      throw error;
    }
  }
};

const getAPIName = (url = "") => {
  return url?.slice(url?.lastIndexOf("/") + 1);
};
