import React from "react";
import { Modal } from "rsuite";
import "./PlayStoreModal.scss";
import SellerPlayStoreImg from "../../../assets/images/SellerPlayStoreImg.png";
import GooglePlayIcon from "../../../assets/images/GooglePlayIcon.png";
import SellerHubScreen from "../../../assets/images/SellerHubScreen.png";
import { QRCodeCanvas } from "qrcode.react";
import { MdClose } from "react-icons/md";

const PlayStoreModal = ({ isVisible, onClose }) => {
  const downloadLink =
    "https://play.google.com/store/apps/details?id=com.sellerhub&hl=en_IN";
  return (
    <Modal
      open={isVisible}
      onClose={onClose}
      footer={null}
      className="download-app-modal"
    >
      <div className="playStore-button" onClick={onClose}>
        <MdClose />
      </div>
      <Modal.Body>
        <div className="qr-footer-logos">
          <img src={SellerHubScreen} alt="Easy Pay" />
        </div>

        <div className="qr-container">
          <h4>Scan to download the app</h4>
          <div className="qr-code-wrapper">
            <a href={downloadLink} target="_blank" rel="noopener noreferrer">
              <QRCodeCanvas
                value={downloadLink}
                size={180}
                level="H"
                imageSettings={{
                  src: SellerPlayStoreImg,
                  height: 70,
                  width: 70,
                  excavate: true,
                }}
              />
              <span className="playStore-hover-text">
                Scan or click on this QR
              </span>
            </a>
          </div>
          <span>Scan or click on this QR</span>
          <div className="dashed-or">
            <span>OR</span>
          </div>

          <div className="google-icon">
            <a href={downloadLink} target="_blank" rel="noopener noreferrer">
              <img src={GooglePlayIcon} alt="GooglePlayIcon" />
            </a>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PlayStoreModal;
