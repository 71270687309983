import React, { useEffect, useState } from "react";
import Sidenav from "./sidenav/Sidenav";
import Topnav from "./topnav/Topnav";
import "./Home.scss";
import { Outlet } from "react-router-dom";
import postal from "postal";
import Spinner from "../Common/Loading/Spinner";
import Footer from "./Footer/Footer";
import axios from "axios";

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [myTheme, setMyTheme] = useState(localStorage.getItem("theme"));
  const getData = (val) => {
    setMyTheme(val);
  };

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        sessionStorage.setItem("ipAddress", response.data.ip);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    fetchIpAddress();
  }, []);

  var subscription = postal.subscribe({
    channel: "baas",
    topic: "loader",
    callback: (data) => {
      setLoading(data);
    },
  });
  return (
    <>
      {loading ? <Spinner /> : <></>}
      <div className="home-container">
        <Sidenav myTheme={myTheme} />
        <div className="footer-page-wrapper">
          <div className="page-wrapper">
            <Topnav sendData={getData} />
            <div id="main" className="main-panel">
              <Outlet context={{myTheme: myTheme}}/>
            </div>
          </div>
          <Footer myTheme={myTheme} />
        </div>
      </div>
    </>
  );
};

export default Home;
