import React, { useCallback, useContext, useEffect, useState } from "react";
import "./Topnav.scss";
import AuthContext from "../../../Store/auth-context";
import { Link, useNavigate } from "react-router-dom";
import { RiMenu2Line } from "react-icons/ri";
import { BiFullscreen, BiSun, BiMoon } from "react-icons/bi";
import { MdFullscreen } from "react-icons/md";
import bblack from "../../../assets/images/bblack.png";
import bwhite from "../../../assets/images/bwhite.png";
import { IoIosArrowDown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { fetchSellerDetails } from "../../../Store/reducers/sellerSlice";
import { generateRandomNumber, partnerDetails } from "../../../util/utils";
import { QRCodeCanvas } from "qrcode.react";
import { GoDownload } from "react-icons/go";
import QrModal from "./QrModal";
import QrGif from "../../../assets/images/QR-GIF.2.gif";
import GooglePlayIcon from "../../../assets/images/google-play-icon.svg";
import PlayStoreModal from "./PlayStoreModal";

//import BellNotification from "./BellNotification/BellNotification";

const Topnav = ({ sendData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let domainDetail = partnerDetails();
  const [fullScreen, setFullScreen] = useState(true);
  const [myTheme, setMyTheme] = useState(localStorage.getItem("theme"));
  const authCtx = useContext(AuthContext);
  const [isMaximized, setIsMaximized] = useState(false);
  const [isQrModalVisible, setIsQrModalVisible] = useState(false);
  const [playStoreModal, setPlayStoreModal] = useState(false);

  const { sellerData, sellerStores } = useSelector((state) => state.seller);

  //Qr Handlers
  const showQrModal = () => {
    setIsQrModalVisible(true);
  };

  const closeQrModal = () => {
    setIsQrModalVisible(false);
  };

  useEffect(() => {
    if (!sellerData) {
      const storedAuthData = sessionStorage.getItem("authData");
      const authData = JSON.parse(storedAuthData);
      const mobile = authData?.sellerMobile;
      const info = {
        HEADER: {
          mobile: mobile,
          UDID: sessionStorage.getItem("ipAddress"),
          REQUEST_ID: generateRandomNumber(),
        },
        DATA: {},
      };

      dispatch(fetchSellerDetails(info));
    }
  }, [sellerData, dispatch]);

  useEffect(() => {
    sendData(myTheme);
  });

  const toggleSidenav = () => {
    const rightNav = document.getElementById("sidenav");
    rightNav.classList.toggle("right-nav");
  };

  const addIconsOnlyClass = () => {
    const list = document.body.classList;
    list.toggle("sidenav-icon-only");
    setIsMaximized(!isMaximized);
  };

  window.addEventListener("resize", function () {
    if (window.innerWidth < 992)
      document.body.classList.remove("sidenav-icon-only");
  });

  const openProfileNav = () => {
    const eleClassList = document.getElementById("profile-dropdown").classList;
    eleClassList.toggle("show");
  };

  const openSellerNav = () => {
    const eleClassList = document.getElementById("seller-dropdown").classList;
    eleClassList.toggle("show");
  };

  const fullWindow = useCallback(() => {
    let elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
    setFullScreen(false);
  }, []);

  const smallWindow = useCallback(() => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
    setFullScreen(true);
  }, []);

  const logoutHandler = () => {
    if (localStorage.getItem("theme") === "dark") {
      document.body.classList.remove("dark-theme");
      localStorage.setItem("theme", "light");
    }
    const partnerDetails = sessionStorage.getItem("partnerDetails");
    sessionStorage.clear();
    if (partnerDetails) {
      sessionStorage.setItem("partnerDetails", partnerDetails);
    }
    if (sessionStorage.getItem("ondcUser") === "true") {
      authCtx.logOut();
      navigate("/baas/ONDC/login");
    } else {
      authCtx.logOut();
    }
    document.title = domainDetail?.partnerName;
  };

  // Toggle THEME by this function
  const changeTheme = () => {
    document.body.classList.toggle("dark-theme");

    // Toggle theme value in local storage
    if (localStorage.getItem("theme") === "light") {
      localStorage.setItem("theme", "dark");
      setMyTheme("dark");
    } else {
      localStorage.setItem("theme", "light");
      setMyTheme("light");
    }
  };

  //stay on same theme after refressh or on reopening of website
  if (localStorage.getItem("theme") === "light") {
    document.body.classList.remove("dark-theme");
  } else if (localStorage.getItem("theme") === "dark") {
    document.body.classList.add("dark-theme");
  } else {
    //this code will be executed when user visit website for first time
    localStorage.setItem("theme", "light");
    setMyTheme("light");
  }

  window.addEventListener("click", (event) => {
    if (!event.target.closest(".profile-link")) {
      const profileContainer = document.getElementById("profile-dropdown");
      if (profileContainer) {
        if (profileContainer.classList.contains("show")) {
          profileContainer.classList.remove("show");
        }
      }
      const sellerDropdown = document.getElementById("seller-dropdown");
      if (sellerDropdown && sellerDropdown.classList.contains("show")) {
        sellerDropdown.classList.remove("show");
      }
    }
  });

  const switchSellerHandler = (mobile, sellerCode, store) => {
    if (sellerStores.length !== 0) {
      authCtx.setMobile(mobile);
      authCtx.setSellerCode(sellerCode);
      sessionStorage.setItem("storeName", store.storeName);
      navigate("/seller/dashboard");
    } else {
      const loginUser = sessionStorage.getItem("authData");
      const parsed = JSON.parse(loginUser);
      authCtx.setMobile(parsed?.sellerMobile);
      authCtx.setSellerCode(parsed?.sellerCode);
      sessionStorage.setItem("storeName", parsed?.storeName);
    }
  };

  return (
    <nav className="top-navbar fixed-top d-flex">
      <div className="top-navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
        <a className="top-navbar-brand brand-logo-mini" href="index.html">
          {myTheme === "light" ? (
            <img height="28" src={bblack} alt="mini-title" />
          ) : (
            <img height="28" src={bwhite} alt="mini-title" />
          )}
        </a>
      </div>
      <div className="top-navbar-menu-wrapper d-flex justify-content-between align-items-center">
        <div>
          <button className="navbar-toggler" onClick={addIconsOnlyClass}>
            <RiMenu2Line className="hamburger" />
            <span className="sts-tooltip">
              {isMaximized ? "Maximise" : "Minimise "}
            </span>
          </button>
        </div>
        <ul className="nav justify-content-center align-items-center">
          {/* <li className="nav-item">
            <div className="nav-link radius" onClick={changeTheme}>
              <span className="menu-icon">
                {myTheme === "light" ? <BiMoon /> : <BiSun />}
              </span>
              <span className="menu-title"></span>
            </div>
          </li> */}
          <li className="nav-item">
            <button className="qr-pattern" onClick={showQrModal}>
              <div className="qr-pattern-container">
                <img src={QrGif} alt="QrGif" />
                <span className="qr-tooltip">Download Store QR</span>
              </div>
            </button>
          </li>
          <QrModal
            isVisible={isQrModalVisible}
            onClose={closeQrModal}
            sellerData={sellerData}
          />
          {/* PlayStore Download */}
          <li className="nav-item">
            <button
              className="playStore"
              onClick={() => setPlayStoreModal(true)}
            >
              <div className="playStore-container">
                <img src={GooglePlayIcon} alt="GooglePlayIcon" />
                <span className="playStore-tooltip">Download App</span>
              </div>
            </button>
          </li>
          <PlayStoreModal
            isVisible={playStoreModal}
            onClose={() => setPlayStoreModal(false)}
          />

          <li className="nav-item">
            <div className="nav-link radius">
              <span className="menu-icon">
                {fullScreen ? (
                  <BiFullscreen onClick={fullWindow} />
                ) : (
                  <MdFullscreen onClick={smallWindow} />
                )}
              </span>
              <span className="sts-tooltip">
                {fullScreen ? "Maximise" : "Minimise"}
              </span>
              <span className="menu-title"></span>
            </div>
          </li>
          <li className="nav-item position-relative" onClick={openProfileNav}>
            <div className="nav-link profile-link" href="#">
              <span className="menu-title">
                {sessionStorage.getItem("storeName") || "My Account"}
                <span id="down-arrow" className="arrow">
                  <IoIosArrowDown />
                </span>
              </span>
            </div>
            <div id="profile-dropdown" className="position-absolute end-0">
              <Link className="p-drop-item" to="seller/profile">
                <span className="menu-title">Profile</span>
              </Link>
              {/* BAAS Profile */}
              {/* <Link
                className="p-drop-item"
                to="/profile"
                state={{ data: "personalDataTop" }}
              >
                <span className="menu-title">Profile</span>
              </Link> */}
              <Link className="p-drop-item" to="/change-password">
                <span className="menu-title">Change Password</span>
              </Link>
              <div className="p-drop-item">
                <span
                  onClick={logoutHandler}
                  className="menu-title my-logout-color"
                >
                  Logout
                </span>
              </div>
            </div>
          </li>
          {sellerStores?.length > 1 && (
            <li className="nav-item position-relative" onClick={openSellerNav}>
              <div className="nav-link profile-link" href="#">
                <span className="menu-title">
                  Switch Seller
                  <span id="down-arrow" className="arrow">
                    <IoIosArrowDown />
                  </span>
                </span>
              </div>
              <div id="seller-dropdown" className="position-absolute end-0">
                {/* <Link className="p-drop-item" to="seller/profile">
                    <span className="menu-title">{store.storeName}</span>
                  </Link> */}
                {/* BAAS Profile */}
                {/* <Link
                className="p-drop-item"
                to="/profile"
                state={{ data: "personalDataTop" }}
              >
                <span className="menu-title">Profile</span>
              </Link> */}
                {/* <Link className="p-drop-item" to="/change-password">
                    <span className="menu-title">{store.storeName}</span>
                  </Link> */}
                {sellerData?.stores.map((store, index) => (
                  <div className="p-drop-item" key={index}>
                    <span
                      onClick={() =>
                        switchSellerHandler(
                          store.sellerMobile,
                          store.sellerCode,
                          store
                        )
                      }
                      className="menu-title my-logout-color"
                    >
                      {store.storeName}
                    </span>
                  </div>
                ))}
              </div>
            </li>
          )}

          <li className="nav-item right-nav-toggler">
            <div className="nav-link" onClick={toggleSidenav}>
              <RiMenu2Line />
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Topnav;
